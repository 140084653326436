<template>
  <div class="container-fliud">
    <Header></Header>
<div class="faqInfo">

  <h4 class="faq">Regster</h4>
   <hr>
<div class="gray">
   
        <div class="row" id="tweets">
          <form @submit.prevent="login"> 
                <div class="heading">
                    <h1 style="margin-right:40px">Sign in</h1>
                    <h6>Don't have an account? <router-link to='/register' class="login">Register</router-link></h6>
                </div>
                <span v-if="msg.loginError" class="alert alert-danger" role="alert" style="margin-bottom:20px">{{msg.loginError}}</span>
                <div class="form-group">
                    <label for='email'>Email address</label>
                    <input type="email" class="form-control" v-model="email" id="email" aria-describedby="emailHelp" placeholder="Enter your  email address">
                     <span v-if="msg.email" style="color:red">{{msg.email}}</span>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" v-model="password" placeholder="Password">
                     <span v-if="msg.password" style="color:red">{{msg.password}}</span>
                </div>
                <div class="form-group">
                    <div class="g-recaptcha" data-sitekey="6LcN0AobAAAAADr6drJ5Y2DzDwprPOiJmuXh8Olp"></div>
                </div>
                <button type="submit" class="btn btn-primary form-control">Login
                    <span class="spinner-border text-light" id="spinner" role="status" style="display:none; width:20px; height:20px; margin-left:20px">
                    </span>
                </button>
            </form>
        </div>
</div>

</div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import baseUrl from '../baseUrl'
import Header from '../components/Navbar';
import Footer from '../components/footer'
import $ from 'jquery'


export default {
  name: 'Login',
  components: {
    Header,
   Footer
  },
  data(){
      return{
          email:'',
          password:'',
          msg:[],
          errors:[]
      }
  },
  methods: {
      login(){
         
           const emailRegex= /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email);
           
          //email
           if(this.email ==""){
              this.msg['email']="Enter email address";
              return false
          }else if(!emailRegex){
              this.msg['email']="Invalid email address";
              return false
          }else{
              this.msg['email']=""
          }
          //password
           if(this.password ==""){
              this.msg['password']="Enter password";
              return false
          }else{
              this.msg['password']=""
          }
          $('#spinner').show();
          baseUrl.post('/api/user/login',{
              email:this.email,
              password:this.password
          }).then((res)=>{
              $('#spinner').hide();
              console.log(res)
              this.email="",
              this.password=""
              this.$router.push('/') 
          }).catch((err)=>{
              $('#spinner').hide();
                 if(status =="03"){
                     $("#error").show()
                      console.log(err)
                      this.msg['loginError'] = "Login failed, Invalid username or password!"

                        this.email="",
                        this.password = ""
                        return false
                }else{
                    $("#error").show()
                    this.errors = err.data
                    this.msg['loginError'] = err.message
                }
          })
      }
  },
}
</script>
<style scoped>
.faqInfo{
margin-top: 200px;
}
.gray{
background-color: rgb(255, 255, 255);
padding-bottom: 50px;
display:flex;
justify-content: center;
align-items: center;
}
form{
    border: 1px solid rgb(168, 165, 165);
    padding: 50px;
}
.heading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
}
.faq{
padding-left: 100px;
}
.btn{
    background-color: #97c149;
    border: #97c149;
}
.login{
    color: #97c149;
}

</style>